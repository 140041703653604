<template>
  <div v-if="event && race" class="">
    <div v-if="event.pinned_views && event.pinned_views.includes('COLLECTIVE_PROGRESS')">
      <vue-markdown v-if="race.result_desc" class="markdown mx-4 mt-4" :html="false" :source="race.result_desc" />
      <v-card-title class="subheader pb-0">{{ $t('results.collective-progress') }}</v-card-title>
      <v-card-text v-if="meta"> 
        <p v-if="race.collective_goal && !race.hide_collective_goal">
          {{ $helpers.getGoalDistanceForDisplay($options, race, meta.collective_progress, event) }}
          of
          {{ $helpers.getGoalDistanceForDisplay($options, race, race.collective_goal || race.dist, event) }}
          ({{ ((meta.collective_progress / (race.collective_goal || race.dist)) * 100).toFixed(1) }}%)
        </p>
        <p v-else-if="meta.collective_progress">{{ $helpers.getGoalDistanceForDisplay($options, race, meta.collective_progress, event) }}</p>
        <p v-else>{{ $t('results.collective-progress-not-known') }}</p>
      </v-card-text>
      <v-card-text v-else-if="$store.getters.loading">
        <i><v-icon>fa fa-circle-notch fa-spin</v-icon> {{ $t('shared.loading') }}</i>
      </v-card-text> 
    </div>
    <div v-if="event.pinned_views && event.pinned_views.includes('MAP')">
      <v-card-title class="subheader pb-2"> {{ $t('events.race.progress-map') }}</v-card-title>
      <RaceResultsMap ref="resultMap" :event="event" :race="race" :badges="badges" :meta="meta"/>
    </div>
    <div v-if="event.pinned_views && event.pinned_views.includes('LEADERBOARD')">
      <v-card-title class="subheader pb-0">
        <span class="mr-2">{{$t('results.top-x', { limit: 10 })}}</span>
        <!-- <span class="mr-2">{{ race.name }}</span> -->
      </v-card-title>
      <RaceResultsGrid :event="event" :race="race" :results="results" :meta="meta" :badges="badges" :filter="filter" :show-collective="false" />
      <div v-if="meta && meta.total_count > filter.limit" class="full-width-separator text-center mt-6">
        <v-btn class="" rounded outlined :to="{name: 'race', params: {id: event.id, raceId: race.id }}">{{$t('results.show-all')}}</v-btn>
      </div>
    </div>
    <div v-if="event.pinned_views && event.pinned_views.includes('BADGES')">
      <v-card-title class="subheader pb-0">
        {{$t('events.race.badges')}}
      </v-card-title>
      <RaceBadgesGrid :event="event" :race="race" :badges="badgesForDisplay" @click="badgeSelected" />
      <BadgeDetailsDialog :event="event" :race="race" ref="badgeDialog" class="d-none" />
    </div>

  </div>
</template>

<script>
import eventService from "@/services/eventService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import RaceResultsGrid from "@/components/RaceResultsGrid.vue";
import RaceResultsMap from "@/components/RaceResultsMap.vue";
import RaceBadgesGrid from "@/components/RaceBadgesGrid.vue";
import BadgeDetailsDialog from "@/components/BadgeDetailsDialog";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "RaceCustomView",
  components: {
    VueMarkdown,
    RaceResultsGrid,
    RaceResultsMap,
    RaceBadgesGrid,
    BadgeDetailsDialog,
  },
  props: {
    event: Object,
    race: Object,
  },
  data: () => ({
    meta: null,
    results: null,
    badges: null,
    filter: { limit: 10 },
  }),
  mounted() {
    if (this.event && this.race) {
      this.tryLoadRaceResults();
    } 
  },
  methods: {
    async tryLoadRaceResults() {
      if (!this.results) {
        const response = (await eventService.getRaceResults(this.event.id, this.race.id)).data;
        this.results = response.data.splice(0, this.filter.limit);
        this.meta = response.meta;
        this.badges = (await eventService.getRaceBadges(this.event.id, this.race.id)).data.data;
        if (this.race.route) {
          //this.$refs.resultMap.ensureBadgesLoaded(this.badges);
        }
        if (this.race.route && this.event.pinned_views && this.event.pinned_views.includes('MAP')) {
          const markerResponse = (await eventService.getRaceMarkers(this.event.id, this.race.id)).data;
          let markers = markerResponse.data || [];
          if (markers.length > 0) {
            this.$refs.resultMap.loadMarkers(markers, this.meta);
          }
          else {
            this.$refs.resultMap.loadResults(this.results, this.meta);
          }
        }
      }
    },
    
    async badgeSelected(badge) {
      //this.highlightedBadge = this.badges.find(x => x.id == badge.id);
      this.$nextTick(() => {
        this.$refs.badgeDialog.openById(badge.id);
      });
    },

  },
  computed: {
    badgesForDisplay() {
      return this.badges && this.badges.filter(x => x.metric !== 'SPLIT' && x.metric !== 'AGGREGATION');
    },

  },
  watch: {
  }
};
</script>

<style lang="scss">
  
</style>